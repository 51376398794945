const UNITS = [
  ['year', 24 * 60 * 60 * 1000 * 365],
  ['month', (24 * 60 * 60 * 1000 * 365) / 12],
  ['day', 24 * 60 * 60 * 1000],
  ['hour', 60 * 60 * 1000],
  ['minute', 60 * 1000],
  ['second', 0],
];

function formatRelativeTime(
  value,
  { locale = 'en', from = new Date(), unit = 'second', absolute = false, datetime = null },
) {
  if (typeof Intl.RelativeTimeFormat === 'undefined' || !Intl.RelativeTimeFormat) {
    if (!datetime) {
      return '';
    }

    const format = {
      day: 'numeric',
      month: 'numeric',
    };
    const now = new Date();
    datetime = typeof datetime === 'string' ? new Date(datetime) : datetime;

    if (now.getUTCFullYear() !== datetime.getUTCFullYear()) {
      format.year = 'numeric';
    }

    const dtf = new Intl.DateTimeFormat(locale, format);

    return dtf.format(datetime);
  }

  const rtf = new Intl.RelativeTimeFormat(locale, {
    localeMatcher: 'lookup',
    numeric: 'auto',
  });

  if (!absolute) {
    value = typeof value === 'string' ? new Date(value) : value;
    const elapsed = value - from;

    for (let i = 0; i < UNITS.length; i++) {
      const u = UNITS[i];

      if (Math.abs(elapsed) >= u[1] || u[0] === unit) {
        value = Math.round(elapsed / u[1]);
        unit = u[0];
      }
    }
  }

  return rtf.format(value, unit);
}

export default formatRelativeTime;
