import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  send(event) {
    const target = event.target.dataset.clickThrough ? event.target : event.target.closest('[data-click-through]');
    if (!target) {
      return;
    }

    const clickThroughData = this.createClickThroughDataForEventTarget(target);
    const formData = new FormData();

    for (const [key, value] of Object.entries(clickThroughData)) {
      formData.append(key, value);
    }

    const param = document.querySelector('meta[name=csrf-param]').getAttribute('content');
    const token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    formData.append(param, token);

    navigator.sendBeacon(this.clickThroughUrl, formData);
  }

  createClickThroughDataForEventTarget(target) {
    return {
      ...this.clickThroughData,
      ...JSON.parse(target.dataset.clickThrough),
    };
  }

  get clickThroughData() {
    if (!this.element.dataset.clickThrough) {
      return {};
    }

    return JSON.parse(this.element.dataset.clickThrough);
  }

  get clickThroughUrl() {
    return this.element.dataset.clickThroughUrl;
  }
}
