import ApplicationController from 'modules/application_controller';
import hide from 'plugins/element/hide';
import show from 'plugins/element/show';
import addListener from 'plugins/utilities/add_listener';
import fire from 'plugins/utilities/fire';
import removeListener from 'plugins/utilities/remove_listener';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      listenerId: null,
      customScope: null,
    };
  }

  connect() {
    if (this.isTurboPreview) {
      return;
    }

    this.listenerId = addListener(this.element, 'change', this.handleChange.bind(this));

    this.trigger();
  }

  disconnect() {
    if (this.listenerId) {
      removeListener(this.element, { id: this.listenerId });
      this.listenerId = null;
    }
  }

  handleChange() {
    if (this.element.checked && this.element.tagName.toLowerCase() === 'input') {
      if (this.element.getAttribute('type').toLowerCase() === 'radio') {
        const name = this.element.getAttribute('name');
        const form = this.element.closest('form');

        for (const radioElement of form.querySelectorAll(`input[type="radio"][name="${name}"]`)) {
          if (radioElement !== this.element) {
            fire(radioElement, 'change');
          }
        }
      }
    }

    this.trigger();
  }

  trigger() {
    const dependentElements = this.scopeElement.querySelectorAll(`[data-depend='${this.triggerName}']`);

    for (const el of dependentElements) {
      const dependValue = el.dataset.dependValue;
      const dependInverted = el.dataset.dependInverted === 'true';

      if (dependValue) {
        if (dependValue === this.element.value) {
          this.triggerHelper(el, this.triggerHide, true, dependInverted);
        } else {
          this.triggerHelper(el, this.triggerHide, false, dependInverted);
        }
      } else if (this.element.checked) {
        this.triggerHelper(el, this.triggerHide, true, dependInverted);
      } else if (!this.element.checked) {
        this.triggerHelper(el, this.triggerHide, false, dependInverted);
      }
    }
  }

  triggerHelper(el, triggerHide, active, inverted) {
    if (inverted) {
      if (active) {
        this.triggerInactive(el, triggerHide);
      } else {
        this.triggerActive(el, triggerHide);
      }

      return;
    }

    if (active) {
      this.triggerActive(el, triggerHide);
    } else {
      this.triggerInactive(el, triggerHide);
    }
  }

  triggerActive(el, triggerHide) {
    const disabled = el.dataset.dependDisabled === 'true';

    el.disabled = disabled;

    if (triggerHide) {
      show(el, { useHiddenAttr: true });
    }
  }

  triggerInactive(el, triggerHide) {
    el.disabled = true;

    if (triggerHide) {
      hide(el, { useHiddenAttr: true });
    }
  }

  get listenerId() {
    return this.props.listenerId;
  }

  set listenerId(value) {
    this.props.listenerId = value;
  }

  get triggerName() {
    return this.element.getAttribute('data-trigger');
  }

  get triggerHide() {
    return this.element.hasAttribute('data-trigger-hide') && this.element.getAttribute('data-trigger-hide') !== 'false';
  }

  get scopeElement() {
    if (this.props.customScope === null) {
      this.props.customScope = this.element.closest(`[data-trigger-scope~="${this.triggerName}"]`) || false;
    }

    if (this.props.customScope) {
      return this.props.customScope;
    }

    return document;
  }
}
