import pushDataLayer from './push_data_layer';

function pushFormSubmissionToDataLayer({ formName, ...rest }) {
  const formSubmission = {
    form_name: formName,
    ...rest,
  };

  pushDataLayer({ event: 'form_submission', ...formSubmission });
}

export default pushFormSubmissionToDataLayer;
