import RelativeTime from 'modules/relative_time';

import ExtendedTimeController from './extended_time_controller';

export default class extends ExtendedTimeController {
  initialize() {
    super.initialize();

    this.props = {
      ...(this.props || {}),
      updateIntervalId: null,
    };
  }

  connect() {
    super.connect();

    if (!this.updateIntervalId) {
      const timeoutSeconds = this.precise ? 1 : 60;

      this.updateIntervalId = window.setInterval(this.updateElement.bind(this), timeoutSeconds * 1000);
    }
  }

  disconnect() {
    if (this.updateIntervalId) {
      clearInterval(this.updateIntervalId);
      this.updateIntervalId = null;
    }
  }

  updateElement() {
    this.element.textContent = this.formattedDate || '';
  }

  get formattedDate() {
    if (!this.datetime) {
      return null;
    }

    return new RelativeTime(this.datetime, this.locale, this.precise).toString();
  }

  get updateIntervalId() {
    return this.props.updateIntervalId;
  }

  set updateIntervalId(value) {
    this.props.updateIntervalId = value;
  }

  get precise() {
    return this.element.dataset.precise === 'true';
  }
}
