import toUnderscore from 'plugins/string/to_underscore';

import deepTransformKeys from './deep_transform_keys';
import pushDataLayer from './push_data_layer';

function pushPageViewToDataLayer({ event = 'page_view', type, title, path, location, ...rest }) {
  const page = {
    type,
    title,
    path,
    location,
    ...deepTransformKeys(rest, (key) => toUnderscore(key)),
  };

  pushDataLayer({
    event,
    page,
  });
}

export default pushPageViewToDataLayer;
