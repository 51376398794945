import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get values() {
    return {
      breakpoints: {
        type: Object,
        default: {
          xs: 320,
          sm: 384,
          md: 448,
          lg: 512,
          xl: 576,
          '2xl': 672,
          '3xl': 768,
          '4xl': 896,
          '5xl': 1024,
          '6xl': 1152,
          '7xl': 1280,
        },
      },
    };
  }

  initialize() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.updateBreakpointClasses(entry.contentRect.width);
      }
    });
  }

  connect() {
    this.resizeObserver.observe(this.element);
  }

  disconnect() {
    this.resizeObserver.disconnect();
  }

  updateBreakpointClasses(width) {
    const breakpointKeys = Object.keys(this.breakpointsValue).sort(
      (a, b) => this.breakpointsValue[b] - this.breakpointsValue[a],
    );

    for (const key of breakpointKeys) {
      if (width < this.breakpointsValue[key]) {
        this.element.classList.remove(key);

        continue;
      }

      this.element.classList.add(key);
    }
  }
}
