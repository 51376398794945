import isObject from './is_object';

function deepTransformKeys(obj, fn) {
  if (Array.isArray(obj)) {
    return obj.map((val) => deepTransformKeys(val, fn));
  }

  if (isObject(obj)) {
    return Object.keys(obj).reduce((acc, current) => {
      const key = fn(current);
      const val = obj[current];
      acc[key] = val !== null && typeof val === 'object' ? deepTransformKeys(val, fn) : val;
      return acc;
    }, {});
  }

  return obj;
}

export default deepTransformKeys;
