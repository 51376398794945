import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  blurActiveElement(event) {
    const { key, ctrlKey, metaKey, shiftKey } = event;

    if (key !== 'Escape' || ctrlKey || metaKey || shiftKey) return;
    if (!document.activeElement || (document.activeElement.tabIndex < 0 && !document.activeElement.isContentEditable)) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    document.activeElement.blur();
  }
}
