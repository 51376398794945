import ApplicationController from 'modules/application_controller';

import getData from 'plugins/data/get';
import setData from 'plugins/data/set';
import stimulus, { FUSE_MODAL_TRIGGER_CONTROLLER } from 'plugins/stimulus';
import defineTargetValuesFromDataset from 'plugins/utilities/define_target_values_from_dataset';

export default class extends ApplicationController {
  static get targets() {
    return ['item'];
  }

  itemTargetConnected(target) {
    if (this.isTurboPreview) return;
    if (this.isItemConnected(target)) return;

    this.initTrigger(target);
  }

  itemTargetDisconnected(target) {
    this.destroyItem(target);
  }

  destroyAllItems() {
    for (const item of this.itemTargets) {
      this.destroyItem(item);
    }
  }

  destroyItem(item) {
    if (!this.isItemConnected(item)) return;

    setData(item, 'modal', undefined);
  }

  initTrigger(item) {
    const data = {
      values: defineTargetValuesFromDataset(item, 'modal', {
        name: {
          type: String,
          default: 'modalV2',
        },
        withHistory: {
          type: Boolean,
          default: false,
        },
        url: {
          type: String,
          default: undefined,
        },
        urlParams: {
          type: String,
          default: undefined,
        },
        requestMethod: {
          type: String,
          default: 'GET',
        },
        requestContentType: {
          type: String,
          default: undefined,
        },
        requestCsrfToken: {
          type: String,
          default: undefined,
        },
        requestBody: {
          type: String,
          default: undefined,
        },
      }),
    };

    if ((!data.values.url || data.values.url === '#') && item.href) {
      data.values.url = item.href;
    }

    setData(item, 'modal', data);
  }

  itemData(item, type) {
    return getData(item, 'modal')[type];
  }

  itemValues(item) {
    return this.itemData(item, 'values');
  }

  isItemConnected(item) {
    return !!getData(item, 'modal');
  }

  openModal(event) {
    if (!event) return;

    event.preventDefault();

    const target = stimulus.closestTarget(event.target, { [FUSE_MODAL_TRIGGER_CONTROLLER]: 'item' });
    const { name, ...options } = this.itemValues(target);

    if (
      (target.tagName.toLowerCase() === 'a' || target.tagName.toLowerCase() === 'button') &&
      !name &&
      !options.url
    ) {
      return;
    }

    if (target.href && target.href !== '#' && (event.altKey || event.ctrlKey || event.metaKey)) {
      return;
    }

    this.dispatchOnWindow('open', {
      prefix: 'modal',
      detail: { name, trigger: target, options },
    });
  }

  closeModal(event = null) {
    if (!event) return;

    event.preventDefault();

    const target = stimulus.closestStimulusTarget(event.target, { [FUSE_MODAL_TRIGGER_CONTROLLER]: 'item' });

    this.dispatchOnWindow('close', { prefix: 'modal', detail: { name: this.itemValues(target).name, trigger: target } });
  }
}
