import ApplicationController from 'modules/application_controller';
import stimulus from 'plugins/stimulus';

export default class extends ApplicationController {
  connect() {
    if (this.wasTurboPreview) {
      stimulus.setAction(this.element, {
        'turbo:before-visit@document': { [this.identifier]: 'clearWasTurboPreview' },
        'turbo:before-cache@document': { [this.identifier]: 'clearWasTurboPreview' },
      });
    }
  }

  disconnect() {
    if (this.isTurboPreview) {
      this.setWasTurboPreview();
    }
  }

  setWasTurboPreview() {
    document.documentElement.setAttribute('data-was-turbo-preview', '');
  }

  clearWasTurboPreview() {
    document.documentElement.removeAttribute('data-was-turbo-preview');
  }
}
