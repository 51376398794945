import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      datetime: null,
    };
  }

  connect() {
    if (!this.disableTooltip) {
      const title = this.formattedTitle;

      if (title && !this.element.hasAttribute('title')) {
        this.element.setAttribute('title', title);
      }
    }

    const text = this.formattedDate;

    if (text) {
      this.element.textContent = text;
    }
  }

  get formattedDate() {
    return null;
  }

  get formattedTitle() {
    const format = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
    };

    if (!this.datetime) {
      return null;
    }

    const dtf = new Intl.DateTimeFormat(this.locale, format);

    return dtf.format(this.datetime);
  }

  get datetime() {
    if (!this.props.datetime) {
      this.props.datetime = this.element.dataset.datetime ? new Date(this.element.dataset.datetime) : null;
    }

    return this.props.datetime;
  }

  get locale() {
    return this.element.dataset.locale || gon.locale || 'en';
  }

  get disableTooltip() {
    return this.element.dataset.disableTooltip === 'true';
  }
}
