import ApplicationController from 'modules/application_controller';
import defer from 'plugins/utilities/defer';
import Sortable from 'sortablejs';

export default class extends ApplicationController {
  static get targets() {
    return ['list', 'orderId'];
  }

  initialize() {
    this.props = {
      sortable: null,
    };
  }

  connect() {
    if (this.isTurboPreview) {
      return;
    }

    defer(this.initSortable.bind(this));
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }
  }

  initSortable() {
    const animationDuration = this.listTarget.children.length > 20 ? 0 : 150;
    const disabledDragAndDrop = animationDuration === 0 ? navigator.userAgent.indexOf('AppleWebKit') >= 0 : false;

    this.sortable = new Sortable(this.listTarget, {
      animation: animationDuration,
      forceFallback: disabledDragAndDrop,
      sort: true,
      handle: '[data-sortable-handle]',
      draggable: '[data-sortable-item]',
      onSort: () => {
        this.updateOrderIds();
      },
    });

    this.updateOrderIds();
  }

  updateOrderIds() {
    if (!this.hasOrderIdTarget) return;

    for (const [index, target] of this.orderIdTargets.entries()) {
      target.value = index;
    }
  }

  get sortable() {
    return this.props.sortable;
  }

  set sortable(value) {
    this.props.sortable = value;
  }
}
