import ApplicationController from 'modules/application_controller';
import createCookie from 'plugins/cookies/create';
import readCookie from 'plugins/cookies/read';

function buildTrafficSource() {
  const searchEnginesQueryParamKey = [
    ['bing', 'q'],
    ['google', 'q'],
    ['yahoo', 'q'],
    ['baidu', 'q'],
    ['yandex', 'q'],
    ['ask', 'q'],
  ]; // List of search engines

  const referrer = document.referrer && new URL(document.referrer);
  const urlSearchParams = new URLSearchParams(document.location.search);

  let source = '';
  let medium = '';
  let campaign = '';
  let term = '';
  let content = '';
  let utmId = '';

  if (urlSearchParams.get('utm_source')) {
    source = urlSearchParams.get('utm_source');
    medium = urlSearchParams.get('utm_medium');
    campaign = urlSearchParams.get('utm_campaign');
    term = urlSearchParams.get('utm_term');
    content = urlSearchParams.get('utm_content');
    utmId = urlSearchParams.get('utm_id');
  } else if (urlSearchParams.get('gclid')) {
    source = 'google';
    medium = 'cpc';
    campaign = '(not set)';
  } else if (referrer) {
    medium = 'referral';
    source = referrer.host;

    // Extract term for organic source
    for (const [searchEngine, key] of searchEnginesQueryParamKey) {
      if (referrer.host.indexOf(searchEngine) > -1) {
        medium = 'organic';
        source = searchEngine;
        term = referrer.searchParams.get(key) || '(not provided)';
        break;
      }
    }
  }

  if (!source) source = 'direct';
  if (!medium) medium = 'none';
  if (!campaign) campaign = 'direct';

  if (medium === 'referral') {
    campaign = '';
  }

  return {
    source,
    medium,
    campaign,
    term,
    content,
    utmId,
  };
}

function buildTrafficSourceSearchParams() {
  const now = new Date();
  const trafficSource = buildTrafficSource();
  const value = new URLSearchParams();

  value.set('utm_source', trafficSource.source);
  value.set('utm_medium', trafficSource.medium);
  value.set('utm_campaign', trafficSource.campaign);
  value.set('utm_term', trafficSource.term);
  value.set('utm_content', trafficSource.content);
  value.set('utm_id', trafficSource.utmId);
  value.set('landing_url', window.location.toString());
  value.set('datetime', now.toISOString());
  value.set('date', now.toISOString().split('T')[0].replace(/-/g, ''));

  return value.toString();
}

function createTrafficSourceCookies({ expireDays = 168 } = {}) {
  const currentTrafficSourceSearchParams = buildTrafficSourceSearchParams();
  const currentTrafficSourceBase64 = btoa(currentTrafficSourceSearchParams);

  const firstTrafficSource = readCookie('first_traffic_source');

  if (!firstTrafficSource) {
    createCookie('first_traffic_source', currentTrafficSourceBase64, expireDays * 24 * 60);
  }

  createCookie('last_traffic_source', currentTrafficSourceBase64, expireDays * 24 * 60);
}

export default class extends ApplicationController {
  static get targets() {
    return ['firstVisit', 'lastVisit', 'gaClientId'];
  }

  connect() {
    createTrafficSourceCookies();
  }

  firstVisitTargetConnected(element) {
    element.value = this.firstTrafficSourceBase64;
  }

  lastVisitTargetConnected(element) {
    element.value = this.lastTrafficSourceBase64;
  }

  gaClientIdTargetConnected(element) {
    element.value = this.gaClientId;
  }

  get firstTrafficSourceBase64() {
    return readCookie('first_traffic_source');
  }

  get lastTrafficSourceBase64() {
    return readCookie('last_traffic_source');
  }

  get gaClientId() {
    return readCookie('_ga');
  }
}
