import ApplicationController from 'modules/application_controller';
import remove from 'plugins/element/remove';
import defer from 'plugins/utilities/defer';

export default class extends ApplicationController {
  static get targets() {
    return ['template', 'empty', 'list', 'item', 'destroyInput'];
  }

  add(event, position = 'beforeend') {
    let html = this.templateTarget.innerHTML.slice();
    html = html.replaceAll('REPLACE_WITH_CHILD_INDEX', this.nextNestedChildIndex);

    this.listTarget.insertAdjacentHTML(position, html);

    if (this.hasEmptyTarget && !this.emptyTarget.hidden) {
      this.emptyTarget.hidden = true;
    }

    defer(() => this.dispatch('added', { target: this.listTarget }));
  }

  append(event) {
    this.add(event);
  }

  prepend(event) {
    this.add(event, 'afterbegin');
  }

  delete(event) {
    if (!this.confirmDelete(event.target)) return;

    defer(() => this.dispatch('removed', { target: this.listTarget }));

    const item = this.itemTargets.find((itemTarget) => itemTarget.contains(event.target));

    if (item.dataset.nestedFormNewRecord === 'true') {
      remove(item);

      if (this.hasEmptyTarget && this.visibleItems === 0) {
        this.emptyTarget.hidden = false;
      }

      return;
    }

    const destroyInputTarget = this.destroyInputTargets.find((inputTarget) => item.contains(inputTarget));

    destroyInputTarget.value = 1;
    item.hidden = true;

    if (this.hasEmptyTarget && this.visibleItems === 0) {
      this.emptyTarget.hidden = false;
    }
  }

  confirmDelete(target) {
    if (target.dataset.confirmDelete) {
      const c = window.confirm(target.dataset.confirmDelete);

      if (!c) return false;
    }

    return true;
  }

  get nextNestedChildIndex() {
    return new Date().getTime();
  }

  get visibleItems() {
    if (!this.hasItemTarget) return 0;

    return this.itemTargets.reduce((sum, target) => (target.hidden ? sum : sum + 1), 0);
  }
}
