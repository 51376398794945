import ChoicesAutocompleteController from '@/fuse/javascript/choices_autocomplete_controller';
import Choices from 'choices.js';
import 'flag-icons/css/flag-icons.css';

export default class extends ChoicesAutocompleteController {
  renderChoice(templateOptions, data, itemSelectText) {
    let content = data.label;

    if (data.customProperties.country_char_code) {
      content = `
        <span class="tw-mr-2 tw-shrink-0 tw-rounded-sm fi fi-${data.customProperties.country_char_code.toLowerCase()}"></span>
        <span class="tw-break-normal tw-mr-2">${data.label}</span>
        <span class="tw-inline-block tw-rounded tw-border tw-border-white/68 tw-bg-white/15 tw-px-1 tw-text-sm tw-break-keep">
          +${data.value}
        </span>
      `;
    }

    const element = Choices.defaults.templates.choice(templateOptions, data, itemSelectText);
    element.classList.add('tw-flex', 'tw-items-center');
    element.innerHTML = content;

    return element;
  }

  setCountryCode(code) {
    this.choices.setChoiceByValue(code);
  }

  get value() {
    return this.choices.getValue();
  }

  get createTemplates() {
    return {
      dropdown(...args) {
        return Object.assign(Choices.defaults.templates.choiceList.call(this, ...args), {
          classList: `${args[0].classNames.listDropdown} tw-min-w-72`,
        });
      },
      item(templateOptions, data) {
        if (data.customProperties.country_char_code) {
          data.label = `+${data.value}`;
        }

        return Choices.defaults.templates.item(templateOptions, data, false);
      },
      choice: this.renderChoice.bind(this),
    };
  }

  get showSelectedValuesInDropdown() {
    return false;
  }
}
