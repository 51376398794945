import ApplicationController from 'modules/application_controller';

const INTERCEPT_REJECT_ERROR = 'fuse:intercept-promise-reject';

export default class extends ApplicationController {
  static get targets() {
    return ['errorNotice', 'errorNoticeText', 'errorNoticeFieldLinkTemplate'];
  }

  initialize() {
    this.props = {
      fields: [],
      errors: [],
      delayedResultId: null,
    };
  }

  reset() {
    this.element.reset();
  }

  toggleErrorNotice(hidden = false) {
    if (!this.hasErrorNoticeTarget || !this.hasErrorNoticeTextTarget) return;

    if (hidden) {
      this.errorNoticeTextTarget.textContent = '';
      this.errorNoticeTarget.hidden = true;

      return;
    }

    const errorItems = [];

    for (const error of this.errors) {
      const field = this.fields.find((f) => f.name === error);

      if (!field) continue;

      let label = field.stimulusController?.labelText || 'Field';

      if (this.hasErrorNoticeFieldLinkTemplateTarget) {
        label = this.errorNoticeFieldLinkTemplateTarget.innerHTML
          .replace('{ID}', error.replace(/[[\]]/g, '_').replace(/_+$/, '').replace(/_+/g, '_'))
          .replace('{CONTENT}', label)
          .trim();
      }

      const message = field.stimulusController?.validityMessageTarget?.textContent.trim() || 'Check the field.';
      const innerHtml = `<li>${label}: ${message}</li>`;

      errorItems.push(innerHtml);
    }

    const html = errorItems.join('');

    this.errorNoticeTextTarget.innerHTML = html;
    this.errorNoticeTextTarget.hidden = html === '';

    this.errorNoticeTarget.hidden = false;
  }

  scrollToErrorField(event) {
    const id = event.target.getAttribute('href').replace('#', '');
    const field = document.getElementById(id);

    if (field) {
      const fieldWrapper = field.closest('[data-controller^="fuse--form"]');

      event.preventDefault();

      (fieldWrapper || field).scrollIntoView({ behavior: 'instant' });
      field.focus({ preventScroll: true });
    }
  }

  validateAndDispatchFuseValidate(event) {
    if (
      !this.validate() ||
      Object.keys(this.errors).length > 0 ||
      !this._dispatchFuseEvents('validate', { cancelable: true })
    ) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      this.toggleErrorNotice(false);
      this.focusFirstFieldWithError();
    } else {
      this.toggleErrorNotice(true);
    }
  }

  dispatchFuseBeforeSubmit(event) {
    if (!this._dispatchFuseEvents('before-submit', { cancelable: true })) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  dispatchFuseSubmitStart(event) {
    this._dispatchFuseEvents('submit-start', { detail: event.detail });
  }

  async dispatchFuseBeforeSubmitRequest(event) {
    event.preventDefault();

    try {
      await this._dispatchFuseEventsWithInterception('before-submit-request', { detail: event.detail });
    } catch (err) {
      if (err.message === INTERCEPT_REJECT_ERROR) {
        this._dispatchFuseEvents('submit-prevented');
        return;
      }

      throw err;
    }

    if (event.detail && event.detail.resume) {
      event.detail.resume();
    }
  }

  dispatchFuseSubmitEndOrWaitForDelayedResult(event) {
    const {
      detail: { success = false, fetchResponse = null },
    } = event;

    if (success && fetchResponse) {
      const delayedResultId = fetchResponse.response.headers.get('X-SlidesLive-DelayedResultId');
      if (delayedResultId) {
        this.props.delayedResultId = delayedResultId;
      }
    }

    if (!this.props.delayedResultId) {
      this.dispatchFuseSubmitEnd({ success });
    }
  }

  dispatchFuseSubmitEndFromDelayedResult(event) {
    const {
      detail: { delayedResultId = null, success = true },
    } = event;

    if (!delayedResultId || delayedResultId !== this.props.delayedResultId) {
      return;
    }

    this.props.delayedResultId = null;
    this.dispatchFuseSubmitEnd({ success });
  }

  dispatchFuseSubmitEnd(detail) {
    this._dispatchFuseEvents('submit-end', { detail });

    if (detail.success) {
      this._dispatchFuseEvents('submit-end-success', { detail });
    } else {
      this._dispatchFuseEvents('submit-end-fail', { detail });
    }
  }

  validate() {
    let valid = true;

    for (const field of this.fields) {
      if (field && typeof field.closest === 'function' && field.closest('[hidden]')) continue;

      const fieldValid = field.stimulusController.validate(true);
      if (!fieldValid) {
        valid = false;
      }
    }

    return valid;
  }

  focusFirstFieldWithError() {
    const firstErrorField = this.fields.find((f) => f.name === this.errors[0] && f.stimulusController?.hasInputTarget);

    if (!firstErrorField) return;

    const inputTarget =
      firstErrorField.stimulusController.inputTarget.tabIndex !== -1
        ? firstErrorField.stimulusController.inputTarget
        : firstErrorField.stimulusController.element.querySelector(
            'input:not([tabindex="-1"]), textarea:not([tabindex="-1"])',
          );

    if (!inputTarget) return;

    inputTarget.focus();
  }

  fieldAdded({ detail }) {
    this.fields.push(detail);
  }

  fieldValidityChanged({ detail: { name, isValid } }) {
    if (isValid) {
      this.errors = this.errors.filter((e) => e !== name);
    } else if (!this.errors.includes(name)) {
      this.errors.push(name);
    }
  }

  fieldRemoved({ detail: { name } }) {
    this.errors = this.errors.filter((e) => e !== name);
    this.fields = this.fields.filter((f) => f.name !== name);
  }

  get fields() {
    return this.props.fields;
  }

  set fields(value) {
    this.props.fields = value;
  }

  get errors() {
    return this.props.errors;
  }

  set errors(value) {
    this.props.errors = value;
  }

  get isTurbo() {
    return this.element.dataset.turbo !== 'false';
  }

  _dispatchFuseEvents(eventName, { detail = {}, cancelable = false } = {}) {
    const event = this._dispatchFuseEvent(eventName, { target: this.element, detail, cancelable });
    const windowEvent = this._dispatchFuseEvent(eventName, { target: window, detail, cancelable });

    return !event.defaultPrevented && !windowEvent.defaultPrevented;
  }

  async _dispatchFuseEventsWithInterception(eventName, { detail = {} } = {}) {
    await this._dispatchFuseEventWithInterception(eventName, { target: this.element, detail });
    await this._dispatchFuseEventWithInterception(eventName, { target: window, detail });
  }

  _dispatchFuseEvent(eventName, { target = this.element, detail = {}, cancelable = false } = {}) {
    const dispatchOptions = {
      target,
      prefix: 'fuse',
      bubbles: false,
      cancelable,
      detail: { ...detail, target: this.element },
    };

    return this.dispatch(eventName, dispatchOptions);
  }

  async _dispatchFuseEventWithInterception(eventName, { target = this.element, detail = {} } = {}) {
    const interceptionPromises = [];

    const intercept = () => {
      let interceptPromiseResolve;
      let interceptPromiseReject;

      const interceptPromise = new Promise((resolve, reject) => {
        interceptPromiseResolve = resolve;
        interceptPromiseReject = () => reject(new Error(INTERCEPT_REJECT_ERROR));
      });

      interceptionPromises.push(interceptPromise);

      return { resume: interceptPromiseResolve, cancel: interceptPromiseReject };
    };

    const event = this._dispatchFuseEvent(eventName, {
      target,
      detail: { ...detail, intercept },
    });

    await Promise.all(interceptionPromises);

    return event;
  }
}
