import ApplicationController from 'modules/application_controller';
import stimulus from 'plugins/stimulus';
import now from 'plugins/utilities/now';
import resolveTwClasses from 'plugins/utilities/resolve_tw_classes';
import viewportHeight from 'plugins/utilities/viewport_height';
import viewportWidth from 'plugins/utilities/viewport_width';

export default class extends ApplicationController {
  static get classes() {
    return ['reveal'];
  }

  static get targets() {
    return ['item'];
  }

  static get values() {
    return {
      threshold: {
        type: Number,
        value: 0.1,
      },
      viewportThreshold: {
        type: Number,
        default: 50,
      },
      throttleInterval: {
        type: Number,
        value: 0,
      },
    };
  }

  initialize() {
    this.lastVisibilityUpdateAt = 0;
    this.observedItems = [];
  }

  connect() {
    if (this.isTurboPreview) return;

    stimulus.setAction(this.element, {
      'scroll@window': { [this.identifier]: 'scheduleVisibilityUpdate' },
      'resize@window': { [this.identifier]: 'scheduleVisibilityUpdate' },
    });

    this.lastVisibilityUpdateAt = now();

    for (const target of this.observedItems) {
      this.updateTargetVisibility(target, { instant: this.wasTurboPreview });
    }
  }

  disconnect() {
    this.observedItems = [];
  }

  itemTargetConnected(target) {
    if (this.isTurboPreview) return;

    this.observedItems = [...this.observedItems, target];

    if (this.lastVisibilityUpdateAt > 0) {
      this.scheduleVisibilityUpdate();
    }
  }

  itemTargetDisconnected(target) {
    if (this.isTurboPreview) return;

    this.observedItems = this.observedItems.filter((item) => target !== item);

    if (this.lastVisibilityUpdateAt > 0) {
      this.scheduleVisibilityUpdate();
    }
  }

  isTargetVisible(target) {
    const rect = target.getBoundingClientRect();
    const visibleHeight = Math.min(rect.bottom, viewportHeight()) - Math.max(rect.top, 0);
    const visibleWidth = Math.min(rect.right, viewportWidth()) - Math.max(rect.left, 0);

    const threshold = target.dataset.scrollRevealThreshold
      ? Number(target.dataset.scrollRevealThreshold)
      : this.thresholdValue;
    const viewportThreshold = target.dataset.scrollRevealViewportThreshold
      ? Number(target.dataset.scrollRevealViewportThreshold)
      : this.viewportThresholdValue;

    // if threshold <= 1 it means percentage, otherwise it means pixels
    const decisiveHeight = threshold <= 1 ? rect.height * threshold : Math.min(threshold, rect.height);
    const decisiveWidth = threshold <= 1 ? rect.width * threshold : Math.min(threshold, rect.width);
    const decisiveViewportHeight =
      viewportThreshold <= 1 ? viewportHeight() * viewportThreshold : Math.min(viewportThreshold, viewportHeight());
    const decisiveViewportWidth =
      viewportThreshold <= 1 ? viewportWidth() * viewportThreshold : Math.min(viewportThreshold, viewportWidth());

    return (
      (visibleHeight >= decisiveHeight || visibleHeight >= decisiveViewportHeight) &&
      (visibleWidth >= decisiveWidth || visibleWidth >= decisiveViewportWidth)
    );
  }

  updateTargetVisibility(target, { instant = false } = {}) {
    if (target.dataset.scrollRevealVisible) return;
    if (!this.isTargetVisible(target)) return;

    const revealClasses = target.dataset.scrollRevealClasses
      ? [target.dataset.scrollRevealClasses]
      : this.revealClasses;
    let originalClassName = target.className;

    if (instant) {
      originalClassName = originalClassName
        .replace(/\b\S*(delay|duration|transition|translate|rotate|scale)\S*\b/g, '')
        .replace(/\s+/g, ' ')
        .trim();
    }

    target.dataset.scrollRevealVisible = 'true';
    target.className = resolveTwClasses(originalClassName, ...revealClasses);

    this.observedItems = this.observedItems.filter((item) => target !== item);
  }

  scheduleVisibilityUpdate() {
    if (now() - this.lastVisibilityUpdateAt > this.throttleIntervalValue) {
      this.lastVisibilityUpdateAt = now();

      window.requestAnimationFrame(() => {
        for (const target of this.observedItems) {
          this.updateTargetVisibility(target);
        }
      });
    }
  }
}
