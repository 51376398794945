import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['content'];
  }

  download(event) {
    event.preventDefault();

    const link = document.createElement('a');
    link.href = `data:text/plain;charset=utf-8,${encodeURIComponent(this.content)}`;
    link.download = this.fileNameFromEvent(event);

    link.click();
  }

  fileNameFromEvent(event) {
    return event.target.dataset.downloadContentName || 'content.txt';
  }

  get content() {
    return this.contentTarget.textContent;
  }
}
