import ApplicationController from 'modules/application_controller';
import pushFormSubmissionToDataLayer from 'plugins/utilities/push_form_submission_to_data_layer';

export default class extends ApplicationController {
  static values = {
    formName: String,
    fieldsPrefix: String,
  };

  pushData() {
    const formData = new FormData(this.form);
    const formDataObject = Object.fromEntries(
      formData.entries().filter(([key]) => key.startsWith(this.fieldsPrefixValue)),
    );

    pushFormSubmissionToDataLayer({
      form_name: this.formNameValue,
      form_data: formDataObject,
    });
  }

  get form() {
    return this.element;
  }
}
